/* form control with icon */
.control-icon {
    position: absolute;
    left: calc(10rem / 16);
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(20rem / 16);
    pointer-events: none;
}
.control-icon + .form-control {
    padding-left: calc(40rem / 16);
}

.form-control:disabled {
    cursor: not-allowed;
}
.form-control.is-valid, .was-validated .form-control:valid,
.form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: none !important;
}