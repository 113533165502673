.slide-sm {
    margin-top: calc(30rem/16);
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(70rem/16);
        margin-bottom: calc(70rem/16);
    }
}
.slide {
    margin-top: calc(30rem/16);
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(105rem/16);
        margin-bottom: calc(105rem/16);
    }
}
/* slides with background color / image */
.slide[class*='bg-'] {
    margin: 0;
    padding-top: calc(30rem / 16);
    padding-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(105rem / 16);
        padding-bottom: calc(105rem / 16);
    }
}

.slide--img-bg {
    position: relative;
    margin: 0;
    padding: calc(30rem / 16) 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media screen and (min-width: 768px) {
        margin: 0;
        padding: calc(55rem / 16) 0;
    }
}
.slide--img-bg:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: var(--color-primary-dark);
    opacity: .85;
}