@media screen and (max-width: 767px) {
    .navbar-collapse {
        position: fixed;
        height: calc(100vh - calc(60rem / 16));
        background: #fff;
        left: 0;
        right: 0;
        top: calc(60rem / 16);
        transform: translateX(100%);
        transition: transform 250ms ease-in-out;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling:touch;

        display: flex;
        flex-direction: column;
        align-items: normal;
    }
    .is-open .navbar-collapse {
        transform: translateX(0);
    }
    .navbar-main {
        height: calc(60rem / 16);
    }
    .navbar-nav {
        display: block;
        padding: calc(30rem / 16) calc(40rem / 16);
    }
    .nav-item:not(:last-child) {
        margin-bottom: calc(16rem / 16);
    }
    .navbar-main .nav-link {
        padding-right: calc(24rem / 16);
    }
    .navbar-main .active > .nav-link {
        color: var(--color-primary);
    }
    .navbar-brand {
        width: calc(150rem / 16);
        padding: 0 calc(15rem / 16);
    }

    /* dropdown navs */
    .nav-item__dropdown,
    .nav-item__dropdown__subnav {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
        height: 100vh;
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: calc(28rem / 16) calc(40rem / 16);
        background-color: #fff;
    }
    /* open navs */
    .nav-item.is-open > .nav-item__dropdown,
    .nav-item.is-open > .nav-item__dropdown__subnav {
        transform: translateX(0);
    }

    /* toggle button */
    .navbar-toggler {
        cursor: pointer;
        border: 0;
        color: var(--color-default);
        font-size: calc(10rem / 16);
        font-family: var(--font-default-bold);
        font-weight: 400;
        padding: calc(16rem / 16);
    }
    .is-open .navbar-toggler {
        color: var(--color-primary);
    }
    .navbar-toggler__bar {
        display: block;
        width: calc(24rem / 16);
        height: calc(2rem / 16);
        background: currentColor;
        transition: transform 250ms ease-in-out;
        transform-origin: left center;
    }
    .navbar-toggler__bar:not(:first-child) {
        margin-top: calc(4rem / 16);
    }
    .navbar-toggler__bar:nth-child(2) {
        transform: scaleX(.75);
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    }
    .is-open .navbar-toggler__bar:nth-child(2) {transform: scaleX(0); opacity: 0;}

    .is-open .navbar-toggler__bar {
        transform: rotate(45deg) translateX(4px) translateY(-6px);
    }
    .is-open .navbar-toggler__bar:last-child {
        transform: rotate(-45deg) translateX(2px) translateY(7px);
    }
    .navbar-toggler__text {
        margin-top: calc(7rem / 16);
    }
    .navbar-toggler__text__close,
    .is-open .navbar-toggler__text__open {
        display: none;
    }
    .is-open .navbar-toggler__text__close {
        display: block;
    }
    .navbar-toggler:focus,
    .navbar-toggler:hover {
        outline: none;
    }

    /* dropdown title */
    .nav-item__dropdown-title {
        border-bottom: calc(1rem / 16) solid #A8A8A5;
        padding-bottom: calc(22rem / 16);
        margin-bottom: calc(25rem / 16);
    }
    .nav-item__dropdown-title__text:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: calc(1rem / 16);
        background: currentColor;
    }
    .nav-item__dropdown-title__icon{
        font-size: calc(8rem / 16);
        margin-right: calc(6rem / 16);
        transform: rotate(90deg);
        vertical-align: .2em;
    }
}