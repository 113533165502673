html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(14rem / 16);
    line-height: calc(20/14);

    @media screen and (min-width: 768px) {
        font-size: var(--font-size-default);
        line-height: calc(26/16);
    }
}
b,
strong,
.font-default-bold,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
.font-default-light {
    font-family: var(--font-default-light);
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
    font-weight: 400;
}

h1, .h1 {
    font-size: calc(40rem / 16);
    letter-spacing: calc(-1rem / 16);
    line-height: calc(65/60);
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        font-size: calc(60rem / 16);
    }
}
h2, .h2 {
    font-size: calc(35rem / 16);
    line-height: calc(53/40);
    margin-bottom: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(40rem / 16);
    }
}
h3, .h3 {
    font-size: calc(22rem / 16);
    line-height: calc(39/30);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }
}
h4, .h4 {
    font-size: calc(18rem/16);
    line-height: calc(25/18);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
        line-height: calc(26/20);
    }
}
h5, .h5 {
    font-size: calc(18rem/16);
}
h6, .h6 {
    font-size: 1em;
}