.map__infobox {
    font-size: 1rem;
    @media (min-width: 768px) {
        line-height: calc(26/16);
        padding: 1.25rem;
    }
}

.infobox {
    border: 1px solid #E3E3E3;
    margin-top: 1rem;
    @media (min-width: 768px) {
        min-height: 320px;
    }
}
