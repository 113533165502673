.quickfinder {
    padding: calc(24rem / 16) calc(20rem / 16);
    margin-bottom: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        transform: translateY(-50%);
        margin-bottom: 0;
        padding: calc(24rem / 16) calc(15rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(24rem / 16) calc(30rem / 16);
    }
}
.btn.quickfinder__btn {
    padding-left: calc(10rem / 16);
    padding-right: calc(10rem / 16);
}