.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: calc(10rem / 16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a:not(.btn) {
    color: var(--color-primary-light);
    text-decoration: underline;
}
.wysiwyg a:not(.btn):hover,
.wysiwyg a:not(.btn):focus,
.wysiwyg a:not(.btn):active {
    color: var(--color-primary);
}

.bg-primary .wysiwyg a:not(.btn) {
    color: white;
    text-decoration: underline;
}
.bg-primary .wysiwyg a:not(.btn):active,
.bg-primary .wysiwyg a:not(.btn):focus,
.bg-primary .wysiwyg a:not(.btn):hover {
    color: var(--color-primary-light);
}

.wysiwyg ul,
.wysiwyg ol {
    padding-left: calc(10rem / 16);
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding-left: calc(30rem / 16);
        margin-bottom: calc(30rem / 16);
    }
}
.wysiwyg ul {
    list-style: none;
}
.wysiwyg ol > li {
    margin-left: calc(16rem/16);
    padding-left: calc(3rem / 16);

    @media screen and (min-width: 768px) {
        padding-left: calc(12rem / 16);
    }
}
.wysiwyg ul > li {
    position: relative;
    padding-left: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        padding-left: calc(28rem / 16);
    }
}
.wysiwyg li + li {
    margin-top: calc(6rem / 16);
    @media screen and (min-width: 768px) {
        margin-top: calc(10rem / 16);
    }
}
.wysiwyg ul > li:before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(7rem / 16);
    width: calc(8rem / 16);
    height: calc(8rem / 16);
    border-radius: 50%;
    background: currentColor;

    @media screen and (min-width: 768px) {
        top: calc(5rem / 16);
        width: calc(10rem / 16);
        height: calc(10rem / 16);
    }
}

.wysiwyg-area--sm .wysiwyg-area__content {
    max-width: calc(829rem / 16);
    margin-left: auto;
    margin-right: auto;
}
