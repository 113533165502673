.navbar-main {
    z-index: 5;
    padding: 0;
    background: #fff;
    border-bottom: calc(1rem / 16) solid var(--color-dark-grey);

    @media screen and (min-width: 768px) {
        padding: 0;
    }
}
.navbar.is-affix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}
.navbar-brand {
    margin: 0;
    padding: calc(16rem / 16) 0;
    @media (max-width: 991px) {
        max-width: 200px;
    }
}
.navbar-main .nav-item {
    @media screen and (min-width: 768px) {
        position: relative;
    }
}
.navbar-nav > .nav-item {
    @media screen and (min-width: 768px) {
        padding-bottom: calc(24rem / 16);
    }
}
.navbar-nav > .nav-item:not(:last-child) {
    @media screen and (min-width: 768px) {
        margin-right: calc(40rem / 16);
    }
}
.navbar-main .nav-link {
    position: relative;
    padding-bottom: calc(4rem / 16);
}
@media screen and (min-width: 768px) {
    .navbar-nav > .nav-item > .nav-link:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: var(--color-primary);
        transition: transform 200ms ease-in-out;
        transform: scaleX(0);
        transform-origin: center;
    }
    .navbar-nav > .nav-item.active > .nav-link:after,
    .navbar-nav > .nav-item:hover > .nav-link:after {
        transform: scaleX(1);
    }
}