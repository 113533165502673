.location-teaser {
    border: calc(1rem / 16) solid var(--color-dark-grey);
    font-size: calc(16rem / 16);
    line-height: calc(26/16);
}
.location-teaser__img {
    transition: transform 200ms ease-in-out;
    transform-origin: center;
}
.location-teaser:hover .location-teaser__img {
    transform: scale(1.02);
}
.location-teaser__content {
    padding: calc(15rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(24rem / 16) calc(24rem / 16) calc(35rem / 16);
    }
}
.location-teaser__content__state {
    position: relative;
    padding-left: calc(20rem / 16);
    font-size: calc(16rem / 16);
    margin-top: calc(10rem / 16);


    @media screen and (min-width: 768px) {
        margin-top: calc(16rem / 16);
        padding-left: calc(28rem / 16);
        font-size: calc(18rem / 16);
    }
}
.location-teaser__content__state:before {
    content: '';
    width: calc(14rem / 16);
    height: calc(14rem / 16);
    border-radius: 50%;
    background: var(--color-success);
    position: absolute;
    left: 0;
    top: 5px;

    @media screen and (min-width: 768px) {
        width: calc(16rem / 16);
        height: calc(16rem / 16);
    }
}
.location-teaser__content__state--closed:before {
    background: var(--color-danger);
}