html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
    .container--no-padding-xs {
        padding: 0;
        overflow: hidden;
    }
}
.container.container {
    max-width: calc(1303rem/16);
    width: 100%;
}
.container {
    padding: 0 calc(20rem / 16);

    @media screen and (min-width: 768px){
        padding: 0 calc(24rem / 16);
    }
}
.container-md {
    max-width: calc(1042rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.container-narrow {
    max-width: calc(877rem / 16);
    margin-left: auto;
    margin-right: auto;
}

:target {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}