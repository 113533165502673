@media screen and (min-width: 768px) {
    .embed-responsive-cover{
        height: 100%;
    }
    .embed-responsive-cover .embed-responsive-item{
        width: auto;
        min-height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}