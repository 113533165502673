.product-teaser {
    position: relative;
    overflow: hidden;
    border: calc(1rem / 16) solid var(--color-light-grey);
}
.product-teaser__head {
    position: relative;
    padding: calc(15rem / 16) calc(15rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(15rem / 16) calc(25rem / 16);
    }
}
.product-teaser__head__logo {
    max-width: calc(100rem / 16);
    max-height: calc(40rem / 16);
    position: relative;
    z-index: 1;
}
.product-teaser__content {
    padding: 0 calc(15rem / 16) calc(10rem / 16);
    transition: background 250ms ease-in-out;

    @media screen and (min-width: 1200px) {
        padding: 0 calc(25rem / 16) calc(20rem / 16);
    }
}
.product-teaser__content__price {
    font-size: calc(25rem / 16);
}
.product-teaser__badge {
    position: absolute;
    right: calc(10rem / 16);
    top: calc(10rem / 16);
    width: calc(42rem / 16);
    height: calc(42rem / 16);
    border-radius: 50%;

    @media screen and (min-width: 1200px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
    }
}
.product-teaser__badge__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(20rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(30rem / 16);
    }
}

/* product teaser with link */
.product-teaser--clickable:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    pointer-events: none;
    border: calc(5rem / 16) solid var(--color-primary);
    border-right-color: var(--color-secondary);
    border-bottom-color: var(--color-secondary);
    z-index: 1;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}
.product-teaser--clickable:hover:before {
    opacity: 1;
}
.product-teaser--clickable:hover .product-teaser__content {
    background: var(--color-dark-grey);
}