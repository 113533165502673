.brands-slider {
    padding: 0 calc(32rem/16);
    @media (min-width: 768px) {
        padding: 0 3rem;
    }
}

.brands-slider__item {
    display: flex !important;
    justify-content: center;
    align-items: center;
}