.news-teaser__content{
    padding: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) calc(20rem / 16) calc(25rem / 16);
    }
}
.news-teaser--bordered {
    border: calc(1rem / 16) solid var(--color-dark-grey);
}

/* large */
.news-teaser--lg .news-teaser__content {
    padding: calc(15rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(25rem / 16) calc(15rem / 16) calc(25rem / 16) calc(85rem / 16);
    }
}
.news-teaser--lg .news-teaser__content__title {
    font-size: calc(26rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(40rem / 16);
    }
}