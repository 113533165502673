.datepicker {
    position: relative;
}
.datepicker:before {
    content: var(--icon-calendar);
    font-family: iconfont;
    position: absolute;
    left: calc(10rem / 16);
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-text-default);
    font-size: calc(20rem / 16);
    pointer-events: none;
}
.datepicker .form-control {
    padding-left: calc(40rem / 16);
}
.ui-datepicker .ui-state-disabled {
    opacity: .4;
    cursor: not-allowed;
}
.ui-datepicker {
    z-index: 1000!important;
}