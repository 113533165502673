.img-text-box {
    padding: calc(20rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(25rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(30rem / 16) calc(50rem / 16);
    }
}
.img-text-box__title {
    font-size: calc(26rem / 16);
    line-height: calc(35/30);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(40rem / 16);
        line-height: calc(53/40);
    }
}
.img-text-box__subtitle {
    font-size: calc(16rem / 16);
    line-height: calc(20/16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.img-text-box__text {
    margin-top: calc(15rem / 16);

    @media screen and (min-width: 1400px) {
        margin-top: calc(38rem / 16);
    }
}

/* large text box */
.img-text-box--lg {
    @media screen and (min-width: 1400px) {
        padding: calc(55rem / 16) calc(50rem / 16);
    }
}