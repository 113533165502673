.teaser-slider-container {
    overflow: hidden;
    position: relative;
}
.teaser-slider {
    position: static;
}
.teaser-slider .slick-list {
    overflow: visible;
}
.teaser-slider .slick-slide > div {
    width: 100%;
}
.teaser-slider .slick-arrow.slick-disabled {
    opacity: 0;
}