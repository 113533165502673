.metabar {
    font-size: calc(14rem / 16);
    padding: calc(20rem / 16) calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(8rem / 16) 0 calc(6rem / 16);
    }
}
.metabar__item:not(:last-child) {
    margin-bottom: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
        margin-right: calc(30rem / 16);
    }
}
.metabar__item__icon {
    vertical-align: -.1em;
    font-size: calc(15rem / 16);
    margin-right: calc(7rem / 16);
}