.hero {
    position: relative;
    overflow: hidden;
}
.hero__content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 28%;

    @media screen and (max-width: 767px) {
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
    }
}
.hero__content--centered {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
}
.hero__image {
    position: relative;
}
.hero__image.with-overlay:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(225.19deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 100%);
}