@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1713272562425/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1713272562425/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1713272562425/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-1taggratis:before { content: "\EA01" }
.icon-addthis:before { content: "\EA02" }
.icon-arrow-left:before { content: "\EA03" }
.icon-arrow-right:before { content: "\EA04" }
.icon-bezahlmodus:before { content: "\EA05" }
.icon-calendar:before { content: "\EA06" }
.icon-checkmark:before { content: "\EA07" }
.icon-chevron:before { content: "\EA08" }
.icon-depot:before { content: "\EA09" }
.icon-earlypickup:before { content: "\EA0A" }
.icon-elements:before { content: "\EA0B" }
.icon-facebook:before { content: "\EA0C" }
.icon-fast-lane:before { content: "\EA0D" }
.icon-halber-preis:before { content: "\EA0E" }
.icon-instagram:before { content: "\EA0F" }
.icon-kinderermaessigung:before { content: "\EA10" }
.icon-linkedin:before { content: "\EA11" }
.icon-lounge:before { content: "\EA12" }
.icon-mail:before { content: "\EA13" }
.icon-online-buchen:before { content: "\EA14" }
.icon-phone:before { content: "\EA15" }
.icon-pinterest:before { content: "\EA16" }
.icon-poi:before { content: "\EA17" }
.icon-sale:before { content: "\EA18" }
.icon-search:before { content: "\EA19" }
.icon-service:before { content: "\EA1A" }
.icon-skischuhverleih:before { content: "\EA1B" }
.icon-skischule:before { content: "\EA1C" }
.icon-skiservice:before { content: "\EA1D" }
.icon-skiset-verleih:before { content: "\EA1E" }
.icon-skiverleih:before { content: "\EA1F" }
.icon-snapchat:before { content: "\EA20" }
.icon-sorglospaket:before { content: "\EA21" }
.icon-testandbuy:before { content: "\EA22" }
.icon-touren:before { content: "\EA23" }
.icon-twitter:before { content: "\EA24" }
.icon-umkleide:before { content: "\EA25" }
.icon-wc:before { content: "\EA26" }
.icon-whatsapp:before { content: "\EA27" }
.icon-wlan:before { content: "\EA28" }
.icon-world:before { content: "\EA29" }
.icon-youtube:before { content: "\EA2A" }


:root {
--icon-1taggratis: "\EA01";
    --icon-addthis: "\EA02";
    --icon-arrow-left: "\EA03";
    --icon-arrow-right: "\EA04";
    --icon-bezahlmodus: "\EA05";
    --icon-calendar: "\EA06";
    --icon-checkmark: "\EA07";
    --icon-chevron: "\EA08";
    --icon-depot: "\EA09";
    --icon-earlypickup: "\EA0A";
    --icon-elements: "\EA0B";
    --icon-facebook: "\EA0C";
    --icon-fast-lane: "\EA0D";
    --icon-halber-preis: "\EA0E";
    --icon-instagram: "\EA0F";
    --icon-kinderermaessigung: "\EA10";
    --icon-linkedin: "\EA11";
    --icon-lounge: "\EA12";
    --icon-mail: "\EA13";
    --icon-online-buchen: "\EA14";
    --icon-phone: "\EA15";
    --icon-pinterest: "\EA16";
    --icon-poi: "\EA17";
    --icon-sale: "\EA18";
    --icon-search: "\EA19";
    --icon-service: "\EA1A";
    --icon-skischuhverleih: "\EA1B";
    --icon-skischule: "\EA1C";
    --icon-skiservice: "\EA1D";
    --icon-skiset-verleih: "\EA1E";
    --icon-skiverleih: "\EA1F";
    --icon-snapchat: "\EA20";
    --icon-sorglospaket: "\EA21";
    --icon-testandbuy: "\EA22";
    --icon-touren: "\EA23";
    --icon-twitter: "\EA24";
    --icon-umkleide: "\EA25";
    --icon-wc: "\EA26";
    --icon-whatsapp: "\EA27";
    --icon-wlan: "\EA28";
    --icon-world: "\EA29";
    --icon-youtube: "\EA2A";
    
}