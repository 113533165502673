@font-face {
    font-family: 'DINPro-Bold';
    src: url('/static/fonts/DINPro-Bold.woff2') format('woff2'),
    url('/static/fonts/DINPro-Bold.woff') format('woff');
    font-weight: normal;
    font-display: block;
}

@font-face {
    font-family: 'DINPro-Regular';
    src: url('/static/fonts/DINPro-Regular.woff2') format('woff2'),
    url('/static/fonts/DINPro-Regular.woff') format('woff');
    font-weight: bold;
    font-display: block;
}

@font-face {
    font-family: 'DINPro-Light';
    src: url('/static/fonts/DINPro-Light.woff2') format('woff2'),
    url('/static/fonts/DINPro-Light.woff') format('woff');
    font-weight: bold;
    font-display: block;
}