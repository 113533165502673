.select {
    position: relative;
}
.select>select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:before {
    font-family: "iconfont";
    content: var(--icon-chevron);
    font-weight: 600;
    color: var(--color-primary);
    speak: none;
    position: absolute;
    pointer-events: none;
    top: 50%;
    right: calc(15rem / 16);
    font-size: calc(8rem / 16);
    transform: translateY(-50%);
}