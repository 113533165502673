.slick-slider {
    line-height: 0;
}

.slider:not(.slick-initialized) > div + div {
    display: none;
}

.slider-item {
    position: relative;
}

/* row--same-height */
.slider--same-height .slick-slide {
    display: flex;
    flex-grow: 1;
    height: auto;
    flex-direction: column;
}
/*
 * the .row--same-height selector has to be after
 * .row--same-height__item to slick-arrowincrease the specificity
 * in case of chaining the two selectors
 * (which is needed for nested rows)
 */
.slider--same-height.slick-slider .slick-track {
    display: flex;
    flex-direction: row;
}
.slider--same-height.slick-slider {
    display: block;
}

/* this div is created automatically by slick-slider */
.slider--same-height .slick-slide > div {
    display: flex;
    flex-grow: 1;
    height: auto;
}

.slider--same-height .slick-slide > div > .slider--same-height__item {
    flex-grow: 1;
    height: auto;
}

/* slider arrows */
.slick-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: calc(10rem / 16);
    transform: translateY(-50%);
    font-size: calc(7rem / 16);
    transition: color 200ms ease-in-out, opacity 200ms ease-in-out;
    line-height: 1;
    width: calc(30rem / 16);
    height: calc(30rem / 16);
    border-radius: 50%;
    opacity: .9;
    background: #fff;
    color: var(--color-primary);
    text-align: center;
    padding: calc(11rem / 16) calc(5rem / 16);

    @media screen and (min-width: 768px) {
        left: calc(20rem / 16);
    }
}
.slick-arrow.slick-next {
    left: auto;
    right: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        left: auto;
        right: calc(20rem / 16);
    }
}
.slick-arrow:focus {
    outline: none;
}
.slick-arrow:focus,
.slick-arrow:hover {
    color: var(--color-primary);
}
.slick-arrow.slick-disabled {
    color: var(--color-dark-grey);
}

.slick-arrow--lg {
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    font-size: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        left: calc(40rem / 16);
    }
}
.slick-arrow--lg.slick-next {
    @media screen and (min-width: 768px) {
        left: auto;
        right: calc(40rem / 16);
    }
}

.slick-arrow--plain.slick-arrow {
    background: transparent;
    opacity: 1;
    width: auto;
    height: auto;
    padding: calc(10rem / 16) calc(4rem / 16);
    font-size: calc(8rem / 16);
}

/* slick dots */
.slick-dots {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    text-align: center;
    position: absolute;
    bottom: calc(30rem / 16);
    left: 0;
    right: 0;
}
.slick-dots li {
    display: inline-block;
}
.slick-dots li:not(:last-child) {
    margin-right: calc(12rem / 16);
}
.slick-dots button {
    position: relative;
    border: 0;
    width: calc(12rem / 16);
    height: calc(12rem / 16);
    cursor: pointer;
    border-radius: 50%;
    background: #fff;
    padding: 0;
}
.slick-dots .slick-active button {
    background: var(--color-primary);
}

.slick-dots--relative {
    position: relative;
    bottom: 0;
    margin-top: calc(15rem / 16);
}
.slick-dots--grey button{
    background: var(--color-dark-grey);
}

.slider-img {
    position: relative;
}
.slider-img__caption {
    position: absolute;
    right: calc(10rem / 16);
    bottom: calc(10rem / 16);
    left: calc(10rem / 16);
    text-shadow: calc(1rem / 16) calc(1rem / 16) calc(2rem / 16) rgba(0,0,0,0.5);
    line-height: calc(20/16);

    @media screen and (min-width: 768px) {
        right: auto;
        max-width: 42%;
        bottom: calc(20rem / 16);
        left: calc(30rem / 16);
    }
}

.img-slider--sm .slider-img__caption {

    @media screen and (min-width: 768px) {
        max-width: 100%;
        right: calc(30rem / 16);
    }
}

/* slider gutters */
[class*=" slider--gutter-"] .slick-track {
    display: flex;
}
[class*=" slider--gutter-"] .slick-slide {
    height: auto;
    display: flex;
}
[class*=" slider--gutter-"] .slick-slide .slider-item {
    height: 100%;
}
.slider--gutter-4 .slick-list {
    margin-left: -.75rem;
    margin-right: -.75rem;
}
.slider--gutter-4 .slider-item {
    padding-left: .75rem;
    padding-right: .75rem;
}