.review-container {
    border: calc(1rem / 16) solid var(--color-dark-grey);
    position: relative;
    padding: calc(20rem / 16) 0;
    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) 0;
    }
}

.review-container:before {
    @media screen and (min-width: 768px) {
        content: '';
        position: absolute;
        left: 50%;
        top: calc(14rem / 16);
        bottom: calc(14rem / 16);
        border-left: calc(1rem / 16) solid var(--color-dark-grey);
    }
}

.review-slider .slick-arrow {
    position: absolute;
    right: 0;
    bottom: 0;
    top: auto;
    left: auto;
    transform: translate(0);

    @media screen and (min-width: 768px) {
        right: calc(12rem / 16);
        bottom: calc(8rem / 16);
    }
}
.review-slider .slick-arrow.slick-prev {
    margin-right: calc(35rem / 16);
}
.review {
    position: relative;
    font-size: calc(16rem / 16);
    line-height: calc(26/16);
    padding: 0 calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) calc(40rem / 16);
    }
}
.review--static {
    padding-bottom: calc(20rem / 16);
    margin-bottom: calc(20rem / 16);
}
.review--static:before {
@media screen and (max-width: 767px) {
    content: '';
    position: absolute;
    left: calc(8rem / 16);
    right: calc(8rem / 16);
    bottom: 0;
    border-bottom: calc(1rem / 16) solid var(--color-dark-grey);

}
}

.review__title {
    font-size: calc(20rem / 16);
    line-height: calc(20/16);
}


.rating {
    position: relative;
    width: calc(130rem / 16);
}
.rating__stars {
    background: url(/static/build/img/star-inactive.png) repeat-x;
    width: 100%;
    height: calc(20rem / 16);
}
.rating__stars--active {
    position: absolute;
    top: 0;
    left: 0;
    background: url(/static/build/img/star-active.png) repeat-x;
    max-width: 100%;
}