.nav-item__dropdown {
    background-color: #fff;

    @media screen and (min-width: 768px) {
        padding: 0;
        box-shadow: 0 calc(2rem / 16) calc(6rem / 16) 0 rgba(0,0,0,0.3);
        display: none;
        position: absolute;
        top: 100%;
        right: calc(-30rem / 16);
        width: calc(280rem / 16);
        font-size: calc(14rem / 16);
        line-height: calc(20/14);
    }
}
.nav-item__dropdown .nav-item + .nav-item {
    @media screen and (min-width: 768px) {
        border-top: calc(2rem / 16) solid var(--color-light-grey);
    }
}
.nav-item__dropdown .nav-item .nav-link {
    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) calc(30rem / 16);
    }
}
.nav-item__dropdown .nav-item:hover > .nav-link {
    @media screen and (min-width: 768px) {
        background: var(--color-light-grey);
    }
}
.nav-link__arrow {
    position: absolute;
    right: calc(-5rem / 16);
    top: calc(-5rem / 16);
    transform: rotate(-90deg);
    font-size: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        right: calc(30rem / 16);
        font-size: calc(6rem / 16);
        display: none;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
    }

    @media screen and (max-width: 767px) {
        width: calc(38rem / 16);
        height: calc(30rem / 16);
        padding: calc(10rem / 16);
    }
}
.nav-item:hover > .nav-link > .nav-link__arrow {
    @media screen and (min-width: 768px) {
        display: block;
    }
}

/* third nav */
.nav-item__dropdown__subnav {
    position: absolute;
    top: 0;
    text-transform: none;

    @media screen and (min-width: 768px){
        display: none;
        right: 100%;
        width: calc(280rem / 16);
        background-color: var(--color-light-grey);
        box-shadow: 0 calc(2rem / 16) calc(6rem / 16) 0 rgba(0,0,0,0.3);
    }
}
@media screen and (min-width: 768px) {
    .nav-item__dropdown__subnav .nav-item + .nav-item {
        border-top: calc(2rem / 16) solid #fff;
    }
    .nav-item__dropdown__subnav .nav-item:hover .nav-link {
        background: var(--color-dark-grey);
    }
    .nav-item__dropdown__subnav .nav-item .nav-link {
        padding: calc(15rem / 16) calc(28rem / 16);
    }
}


/* open navs */
.nav-item.is-open > .nav-item__dropdown,
.nav-item:hover > .nav-item__dropdown,
.nav-item.is-open > .nav-item__dropdown__subnav,
.nav-item:hover > .nav-item__dropdown__subnav {
    @media screen and (min-width: 768px) {
        display: block;
    }
}

/**** 4 items ****/
@media screen and (min-width: 768px) {
    .nav-item:nth-last-child(n+4) .nav-item__dropdown {
        left: calc(-30rem / 16);
        right: auto;
    }
    .nav-item:nth-last-child(n+4) .nav-item__dropdown__subnav {
        left: 100%;
        right: auto;
    }
}