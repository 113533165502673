/* Generated by http://responsive-css.spritegen.com Responsive CSS Sprite Generator */

.icon-flag {
    max-width: 100%;
    background-size: 100%;
    background-image: url('/static/build/img/flags/flag-sprite.png');
    width: calc(30rem / 16);
    height: calc(30rem / 16);
    display: inline-block;
}

.icon-flag-at { background-position: 0 0%; background-size: 100%; }
.icon-flag-de { background-position: 0 33.333333%; background-size: 100%; }
.icon-flag-hu { background-position: 0 66.666667%; background-size: 100%; }
.icon-flag-nl { background-position: 0 100%; background-size: 100%; }