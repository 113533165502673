.clickable {cursor: pointer;}
/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-underline,
.text-underline-hover:hover {
    text-decoration: underline;
}
.text-line-through {
    text-decoration: line-through;
}

/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }


/*Background Color Helper*/
.bg-primary-dark {
    background: var(--color-primary-dark);
    color: #fff;
}
.bg-light-grey {background: var(--color-light-grey);}

.link-text-icon {
    font-size: calc(18rem / 16);
    margin-right: calc(4rem / 16);
    vertical-align: -.25em;

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        margin-right: calc(10rem / 16);
    }
}

.collapse-icon {
    font-size: calc(8rem / 16);
    margin-left: calc(7rem / 16);
    vertical-align: 0.1em;
    transform: scaleY(-1);
    transition: transform 200ms ease-in-out;
}
.collapsed > .collapse-icon {
    transform: scaleY(1);
}

/* bordered cols */
@media screen and (min-width: 768px) {
    .row--bordered-md>[class*=" col"]:not(:first-child), .row--bordered-md>[class^=col]:not(:first-child) {
        border-left: calc(1rem / 16) solid var(--color-dark-grey);
    }
}