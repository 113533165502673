.main-footer a {
    color: var(--color-primary);
}
.main-footer a:hover {
    text-decoration: underline;
}
.main-footer__top {
    padding: calc(20rem / 16) 0;

    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) 0 calc(20rem / 16);
    }
}
.main-footer__top__hl {
    font-size: calc(20rem / 16);
}
.main-footer__top__list li + li {
    margin-top: calc(10rem / 16);
}
.main-footer__bottom {
    padding: calc(30rem / 16) 0 calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(28rem / 16) 0;
    }
}

.social-list {
    font-size: calc(20rem / 16);

    @media screen and (max-width: 767px) {
        font-size: calc(30rem / 16);
        padding-top: calc(20rem / 16);
        margin-top: calc(20rem / 16);
        border-top: 1px solid var(--color-dark-grey);
    }
}
.social-list li {
    display: inline-block;
}
.social-list li + li {
    margin-left: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(15rem / 16);
    }
}