.pricing-table {
    border: calc(1rem / 16) solid #C6C6C6;
}
.pricing-table__head {
    padding: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) 0 0;
    }
}
.pricing-table__head__col {
    position: relative;
    min-height: 134px;

    @media screen and (min-width: 768px) {
        padding: calc(8rem / 16) calc(20rem / 16) calc(24rem / 16);
    }
}
.pricing-table__head__col__badge {
    width: calc(35rem / 16);
    height: calc(35rem / 16);
    border-radius: 50%;
    transform: rotate(-20deg);
    position: absolute;
    top: calc(-30rem / 16);
    left: calc(-8rem / 16);
    font-size: calc(8rem / 16);
    line-height: 1;
    padding: calc(10rem / 16) 0;

    @media screen and (min-width: 768px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
        font-size: calc(13rem / 16);
        padding: calc(16rem / 16) 0;
        left: calc(-14rem / 16);
    }
}
.pricing-table__data {
    font-size: calc(14rem / 16);
    line-height: calc(20/14);
    padding: calc(20rem / 16) calc(10rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0;
        font-size: calc(16rem / 16);
        line-height: calc(26/16);
    }
}
.pricing-table__data:nth-child(even){
    background: var(--color-light-grey);
}
.pricing-table__data + .pricing-table__data {
    border-top: calc(1rem / 16) solid var(--color-dark-grey);
}
.pricing-table__data__col {
    padding: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(18rem / 16) calc(30rem / 16);
    }
}

@media screen and (max-width: 767px) {
    .pricing-table__data__col--title {
        border-bottom: calc(1rem / 16) solid var(--color-dark-grey);
        padding: 0 calc(10rem / 16) calc(5rem / 16);
        margin-bottom: calc(8rem / 16);
    }
    [class^="col"] + [class^="col"] > .pricing-table__data__col {
        border-left: calc(1rem / 16) solid var(--color-dark-grey);
    }
    .bg-primary [class^="col"] + [class^="col"] > .pricing-table__data__col {
        border-color: currentColor;
    }
}
.pricing-table-nav {
    margin-top: calc(12rem / 16);
}
.pricing-table-nav__count {
    font-size: calc(18rem / 16);
}


/* "table" borders */
@media screen and (min-width: 768px) {
    .pricing-table__head__col,
    .pricing-table__data [class^="col"] + [class^="col"] {
        border-left: calc(1rem / 16) solid var(--color-dark-grey);
    }

    .pricing-table__data.bg-primary [class^="col"] {
        border-color: var(--color-primary);
    }
}