.icon-teaser__head {
    width: calc(110rem / 16);
    height: calc(110rem / 16);
    border-radius: 50%;
    position: relative;
    transition: transform 320ms cubic-bezier(0.68, -0.55, 0.27, 1.55);

    @media screen and (min-width: 768px) {
        width: calc(160rem / 16);
        height: calc(160rem / 16);
    }
}
.icon-teaser:hover .icon-teaser__head {
    transform: translateY(calc(-8rem / 16));
}
.icon-teaser__head__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(50rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(80rem / 16);

    }
}
.icon-teaser__title {
    margin: calc(15rem / 16) 0 calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin: calc(32rem / 16) 0 calc(18rem / 16);
    }
}