/* row--same-height */
.row--same-height__item {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}
/*
 * the .row--same-height selector has to be after
 * .row--same-height__item to increase the specificity
 * in case of chaining the two selectors
 * (which is needed for nested rows)
 */
.row--same-height,
.row--same-height.slick-slider .slick-track {
    display: flex;
    flex-direction: row;
}
.row--same-height.slick-slider {
    display: block;
}
.row--same-height > .col,
.row--same-height.slick-slider .col,
.row--same-height > [class^="col"],
.row--same-height > [class*=" col"]{
    display: flex;
    flex-direction: column;
    height: auto;
}