.teaser {
    border: calc(1rem / 16) solid var(--color-dark-grey);
    font-size: calc(16rem / 16);
    line-height: calc(26/16);
}
.teaser__img {
    transition: transform 200ms ease-in-out;
    transform-origin: center;
}
.teaser:hover .teaser__img {
    transform: scale(1.02);
}
.teaser__content {
    padding: calc(15rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(24rem / 16);
    }
}

/* teaser sizes */
.teaser--sm .teaser__content__btn {
    width: 100%;
}