.card-header__btn {
    line-height: 1;
    display: block;
    width: 100%;
    padding: calc(15rem / 16);
    position: relative;
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        padding: calc(35rem / 16) calc(24rem / 16);
        font-size: calc(20rem / 16);
    }
}
.card-header__btn__title {
    font-size: calc(24rem / 16);
    line-height: calc(28/24);

    @media screen and (min-width: 768px) {
        font-size: calc(40rem / 16);
        line-height: calc(53/40);
    }
}
.card-header__btn:focus {
    outline: none;
}
.card-body {
    padding: calc(10rem / 16) calc(15rem / 16) calc(25rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16) calc(24rem / 16) calc(40rem / 16);
    }
}

/* more less info text */
.toggle-info {
    font-size: calc(16rem / 16);
}
.collapsed .toggle-info__less,
.toggle-info__more {
    display: none;
}
.toggle-info__less,
.collapsed .toggle-info__more {
    display: inline-block;
}
.toggle-info__icon {
    font-size: calc(8rem / 16);
    margin-left: calc(6rem / 16);
    vertical-align: 0.1em;
    transform: rotate(-180deg);
    transition: transform 200ms ease-in-out;
}
.collapsed .toggle-info__icon {
    transform: rotate(0);
}