.img-slide {
    position: relative;
}
.img-slide__content {
    margin: calc(-30rem / 16) calc(20rem / 16) 0;

    @media screen and (min-width: 768px) {
        margin: 0;
        font-size: calc(14rem / 16);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(16rem / 16);
    }
}