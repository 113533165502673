.copyright{
    position: absolute;
    right: .5rem;
    bottom: .5rem;
    color: #FFF;
    line-height: 1;
    font-size: .75rem;
    cursor: pointer;
    z-index: 4;
    max-width: 92%;
    display: block;
    height:auto;
    width:auto;
}

@media screen and (max-width: 767px) {
    .pimcore_area_wysiwyg-image-wide .copyright{
        bottom:auto;
        top:.5rem;
    }
}
.copyright__icon{
    line-height: 1;
    padding: calc(1rem/16) calc(5rem/16);
    font-size: 1.125rem;
    text-shadow: 0 0 5px rgba(0, 0, 0, .7);
    position: relative;
    top:auto;
    left:auto;
    bottom:auto;
    right:auto;
}


.copyright__content{
    line-height: 1;
    padding: .375rem 1.5rem .375rem .625rem;
    align-items: baseline;
    background: rgba(0,0,0,.5);
    color: white;
    display: none;
    position: relative;
    top:2px;
}

.copyright__close-icon{
    position: absolute;
    right:.375rem;
    top:.25rem;
    display: block;
    width:1rem;
    height:1rem;
}
.copyright__close-icon:before,
.copyright__close-icon:after{
    content: "";
    position: absolute;
    right:0;
    top:.375rem;
    display: block;
    width:.75rem;
    height:.125rem;
    background:#fff;
    transform: rotate(-45deg);
}
.copyright__close-icon:after{
    transform: rotate(45deg);
}

.copyright.active .copyright__content{
    display: block;
}

.copyright.active .copyright__icon{
    display: none;
}
