@media screen and (max-width: 767px) {
    .scroll-container-xs {
        position: relative;
        padding: 0 5%;
        overflow: hidden;
    }
    .scroll-container-xs:after,
    .scroll-container-xs:before {
        pointer-events: none;
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        width: 5%;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0 0 calc(20rem / 16) #fff;
    }

    .scroll-container-xs:before {
        right: auto;
        left: 0;
    }

    .scroll-container-xs__content {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}