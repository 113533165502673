.default-accordion__collapse + .default-accordion__collapse {
    margin-top: calc(5rem/16);
}

.default-accordion__header {
    display: block;
    width: 100%;
    padding: calc(12rem/16) calc(16rem/16);
    @media (min-width: 768px) {
        padding: calc(16rem/16) calc(32rem/16);
    }
}

.default-accordion__header:focus {
    outline: none;
}

.default-accordion__header__title {
    font-size: 1rem;
    margin: 0;
}

.default-accordion__body {
    padding: calc(16rem/16);
    @media (min-width: 768px) {
        padding: calc(32rem/16);
        padding-bottom: calc(40rem/16);
    }
}

