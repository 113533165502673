.btn {
    font-size: calc(14rem / 16);
    line-height: calc(23/18);
    border-radius: calc(25rem / 16);
    padding: calc(11rem / 16) calc(18rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        padding: calc(14rem / 16) calc(30rem / 16) calc(12rem / 16);

    }
}
.btn-no-styling,
.btn.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.btn-breakable { white-space: normal; }

/* colors */
.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:not(:disabled):not(.disabled):active {color: #fff;}
.btn-outline-white:hover, .btn-outline-white:active, .btn-outline-white:not(:disabled):not(.disabled):active {color: var(--color-primary);}

/* button sizes */
.btn-lg {
    @media screen and (min-width: 768px) {
        padding: calc(14rem / 16) calc(45rem / 16) calc(12rem / 16);
    }
}