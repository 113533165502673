.wysiwyg table,
.table {
    width: 100%;
    border: 0;

    @media screen and (max-width: 767px) {
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}
.wysiwyg table tbody tr:nth-child(even),
.table tbody tr:nth-child(even){
    background: var(--color-light-grey);
}
.wysiwyg table thead th,
.table thead th,
.wysiwyg .table th {
    border: 0;
    background-color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: 400;
    color: #fff;
    text-align: center;
    padding: calc(12rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(17rem / 16) calc(30rem / 16);
    }
}
.wysiwyg table tbody,
.table tbody {
    border: calc(1rem / 16) solid var(--color-dark-grey);
}
.wysiwyg table tbody tr th,
.table tbody tr th {
    font-family: var(--font-default);
    font-weight: 400;
    color: var(--color-text-default);
}
.wysiwyg table tbody tr td,
.table tbody tr td {
    text-align: center;
}
.wysiwyg table tbody tr td, .wysiwyg table tbody tr th,
.table tbody tr td, .table tbody tr th {
    border-top: calc(1rem/16) solid var(--color-dark-grey);
    padding: calc(12rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(17rem / 16) calc(30rem / 16);
    }
}

.wysiwyg table thead tr:first-child:not(:last-child) th {
    background: transparent;
}