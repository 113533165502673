.input-button input[type="radio"],
.input-button input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.input-button {
    cursor: pointer;
    position: relative;
    width: 100%;
}
.input-button__label {
    display: block;
    border-radius: calc(25rem / 16);
    font-size: calc(18rem / 16);
    line-height: calc(23/18);
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    transition: color 200ms ease-in-out, background 200ms ease-in-out;
    padding: calc(14rem / 16) calc(30rem / 16) calc(12rem / 16);
}
.input-button input:checked ~ .input-button__label {
    color: #fff;
    background: var(--color-primary);
}
.input-button:hover .input-button__label {
    background: var(--color-primary);
    color: #fff;
}